import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ComponentType } from 'react'
import { FCC } from '~/core/@types/global'
import { cn } from '~/core/ui/utils'
import ApplicantFeedbackDrawer from '~/features/applicant-feedback/[id]/drawer'
import InterviewFeedbackDrawer from '~/features/interviews/[id]/drawer'
import useApplicantFeedbackStore from '~/lib/features/applicant-feedback/store'
import useCandidateStore from '~/lib/features/candidates/store'
import useInterviewStore from '~/lib/features/interviews/store'
import useBoundStore from '~/lib/store'
import Sidebar from '../Sidebar'
import { withTrialBanner } from '../Subscription/TrialBanner'
import useSubscriptionPlan from '../Subscription/useSubscriptionPlan'
import TaskUserContainer from '../Tasks/TaskUserContainer'
import UploadCVContainer from '../UploadCV/UploadCVContainer'

interface LayoutGridProps {
  background?: string
  className?: string
  sidebarClassName?: string
}

export const LayoutGrid: FCC<LayoutGridProps> = (props) => {
  const { children, background, className, sidebarClassName } = props

  const queryApplicantFeedbackClient = new QueryClient()
  const { data, expiredError } = useSubscriptionPlan()

  const openTaskDrawer = useBoundStore((state) => state.openTaskDrawer)
  const openUploadDrawer = useCandidateStore((state) => state.openUploadDrawer)
  const openInterviewFeedbackDrawer = useInterviewStore(
    (state) => state.openInterviewFeedbackDrawer
  )

  const openApplicantFeedbackDrawer = useApplicantFeedbackStore(
    (state) => state.openApplicantFeedbackDrawer
  )

  return (
    <div className={cn('h-full', background, className)}>
      <Sidebar
        className={sidebarClassName}
        expired={expiredError || data?.isExpired}
      />
      <div className={cn('h-full pl-0 tablet:pl-[61px]', sidebarClassName)}>
        {children}
      </div>
      {openUploadDrawer ? <UploadCVContainer /> : null}
      {openTaskDrawer ? <TaskUserContainer /> : null}
      {openInterviewFeedbackDrawer ? <InterviewFeedbackDrawer /> : null}
      <QueryClientProvider client={queryApplicantFeedbackClient}>
        {openApplicantFeedbackDrawer ? <ApplicantFeedbackDrawer /> : null}
      </QueryClientProvider>
    </div>
  )
}
export const LayoutGridWithTrialBanner = withTrialBanner(LayoutGrid)
export const withLayoutGrid = <T extends object>(
  Component: ComponentType<T>
) => {
  const WithLayoutGridComponent = (props: T) => (
    <LayoutGridWithTrialBanner>
      <Component {...props} />
    </LayoutGridWithTrialBanner>
  )
  return WithLayoutGridComponent
}

export default LayoutGridWithTrialBanner
